import { isUndefined, find, each } from 'underscore';
import isMobile from 'ismobilejs';
import Cookies from 'js-cookie';

(function () {
  angular.module('7Shop.Core')
    .provider('ShopEnvironment', ShopEnvironmentProvider);

  function ShopEnvironmentProvider($windowProvider) {
    var self = this;
    var states = {
      touch: false
    };

    const supportedLanguages = {
      en: {
        path: 'en-US'
      },
      bs: {
        path: 'hr'
      },
      de: {
        path: 'de'
      },
      sr: {
        path: 'sr-Latn'
      },
      srLatn: {
        path: 'sr-Latn'
      },
      srCyrl: {
        path: 'sr-Latn'
      },
      sk: {
        path: 'sk'
      },
      ro: {
        path: 'ro'
      },
      hr: {
        path: 'hr'
      },
      es: {
        path: 'es'
      },
      it: {
        path: 'it'
      },
      tr: {
        path: 'tr'
      },
      pl: {
        path: 'pl'
      },
      me: {
        path: 'me'
      },
      el: {
        path: 'el'
      },
      bg: {
        path: 'bg'
      },
      pt: {
        path: 'pt'
      },
      ru: {
        path: 'ru'
      },
      cs: {
        path: 'cs'
      },
      ka: {
        path: 'ka'
      },
      hu: {
        path: 'hu'
      },
      ke: {
        path: 'ke'
      },
      fr: {
        path: 'fr'
      },
      sl: {
        path: 'sl'
      },
      mkLatn: {
        path: 'mk-Latn'
      },
      mkCyrl: {
        path: 'mk-Latn'
      },
      sq: {
        path: 'sq'
      },
      ht: {
        path: 'ht'
      },
      st: {
        path: 'st'
      },
      sw: {
        path: 'sw'
      },
      id: {
        path: 'id'
      },
      vi: {
        path: 'vi'
      },
      zh: {
        path: 'zh'
      },
      th: {
        path: 'th'
      },
      uk: {
        path: 'uk'
      },
      lv: {
        path: 'lv'
      },
      ko: {
        path: 'ko'
      },
      km: {
        path: 'km'
      }
    };
    // this is maximum cookie expire time (~80 years)
    const COOKIE_EXPIRE_TIME = 2147483647;
    this.dc = null;
    this.language = null;

    function getUrlParameter(name) {
      var $ngWindow = $windowProvider.$get();
      var regex;
      var results;
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      results = regex.exec($ngWindow.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    /**
     * @param {String} newDc
     */
    this.setDc = function (newDc) {
      this.dc = newDc;
    };

    /**
     * @return {String | null}
     */
    this.getDc = function () {
      return this.dc;
    };

    this.setLanguage = function (newLang) {
      this.language = newLang;
    };

    this.getLanguage = function () {
      return this.language;
    };

    this.getLocalePath = function () {
      var locale = supportedLanguages[this.language || 'en'];
      if (!locale) { locale = supportedLanguages.en; }

      return locale.path;
    };

    this.setState = function (state, value) {
      states[state] = value;
    };

    this.getState = function (state) {
      return states[state];
    };

    this.isDebugMode = function () {
      const debugModeParam = (getUrlParameter('logLevel') === 'debug')
      || getUrlParameter('debugMode')
      || localStorage.getItem('settings.logLevel');
      const debugMode = !isUndefined(debugModeParam) ? debugModeParam : false;
      return debugMode;
    };

    this.isRunBySevenCli = () => {
      // We should improve this in future because this can lead to false positive.
      // CLI indeed sends debugMode=true by default but no one can guarantee that someone else
      // will provide same param in different contenxt
      // We must provide explicit flag when running shop from seven cli .e.g sevenCli=true
      var $ngWindow = $windowProvider.$get();
      const urlParams = new URLSearchParams($ngWindow.location.search);
      const debugMode = urlParams.get('debugMode');
      return debugMode;
    };

    this.$get = function (
      $window,
      $log,
      GravitySettings
    ) {
      this.isInTouchMode = function () {
        var forceTouchScreen = JSON.parse(localStorage.getItem('forceTouch'));
        if (forceTouchScreen) return true;
        return getUrlParameter('deviceType') === 'touch'
        || (GravitySettings.getModuleDataKeyValue('module.touch', 'autoDetect') && isMobile().any)
        || (GravitySettings.getModuleDataKeyValue('module.touch', 'forceTouch'));
      };

      this.isNativeTouch = function () {
        return getUrlParameter('deviceType') === 'touch' || isMobile().any;
      };

      function checkIfCookiesEnabled() {
        try {
          Cookies.set('testcookie', true, {
            expires: 1
          });

          const isSet = Cookies.get('testcookie');

          if (!isSet) {
            $log.info('[environmentService] Cookies not available', {
              code: 'S_COOKIES_NOT_AVAILABLE'
            });
            return false;
          }

          Cookies.remove('testcookie');
          return true;
        } catch (err) {
          $log.info('[environmentService] Cookies not available', {
            code: 'S_COOKIES_NOT_AVAILABLE',
            error: err
          });
          return false;
        }
      }

      /**
       * Set app channel
       * @param {string} betshopId
       * @param {string} deviceId
       * @returns {Boolean} - It will return true if app channel is changed
       */
      this.setChannel = function (betshopId, deviceId) {
        const noredirect = localStorage.getItem('s_noredirect') !== null || getUrlParameter('noredirect');
        const { hostname } = $window.location;
        // works only with seven-shop.7platform.net or seven-shop.nsoft.app
        const domain = hostname.split('.').reverse().slice(0, 3).reverse()
          .join('.');
        var availableChannels = GravitySettings.getByKey('channels');
        var channelConfig = GravitySettings.getByKey('channel');
        var channelName = channelConfig.default;
        var overrideChannel = false;
        var foundChannel;

        $log.info('[enivromentService] Checking channel..', {
          url: $window.location.href,
          code: 'S_CHANNEL_CHECK'
        });

        // don't do any redirects if we are on localhost
        if (this.isRunOnLocalhost() || noredirect) {
          return false;
        }

        if (!checkIfCookiesEnabled()) {
          return false;
        }

        // it is possible to override default channel per betshop or device
        if (channelConfig.override) {
          overrideChannel = find(channelConfig.override, function (override) {
            return override.id === betshopId || override.id === deviceId;
          });

          if (overrideChannel) {
            channelName = overrideChannel.channel;
          }
        }

        // find channel url
        foundChannel = find(availableChannels.list, function (cha) {
          return cha.id === channelName;
        });

        // If current cookie does not have value of the curret channel, lets update it.
        // We will skip setting of channel if we are alredy on that URL (just to prevent unecessery reload)
        // See calling context.
        if (foundChannel && !Cookies.get(`s_channel_${foundChannel.id}`)
          && foundChannel.url.indexOf($window.location.origin) === -1) {
          // first remove any previous channel entires
          each(Cookies.get(), (value, key) => {
            if (key.indexOf('s_channel') !== -1) {
              // remove
              Cookies.remove(key, {
                domain: `.${domain}`
              });
            }
          });
          Cookies.set(`s_channel_${foundChannel.id}`, true, {
            domain: `.${domain}`,
            expires: COOKIE_EXPIRE_TIME
          });

          $log.info('[environmentService] Update cookie and reload app', {
            code: 'S_SET_CHANNEL_REDIRECTION',
            foundChannel,
            locationOrigin: $window.location.origin,
            defaultChannel: channelName,
            channelConfig,
            betshopId,
            deviceId
          });
          return true;
        }

        return false;
      };

      this.getLogLevel = () => {
        const url = new URL($window.location.href);
        const level = url.searchParams.get('logLevel')
          || localStorage.getItem('settings.logLevel')
          || GravitySettings.getModuleDataKeyValue('application', 'logLevel')
          || 'warn';
        return level;
      };

      this.isRunOnLocalhost = () => ['localhost', '127.0.0.1'].indexOf($window.location.hostname) >= 0;

      this.isDmActiveRuntime = () => new URLSearchParams($window.location.search).get('ar') === 'dm';

      angular.extend(this, self);
      return self;
    };
  }
})();
