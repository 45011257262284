/* eslint-disable function-paren-newline */
/* eslint-disable import/no-unresolved */
import { has, each } from 'underscore';

(function () {
  angular.module('7Shop.Bootstrap')
    .service('ShopBootstrap', ShopBootstrap);

  function ShopBootstrap(
    $rootScope,
    $q,
    $window,
    $http,
    $log,
    $timeout,
    $state,
    $translate,
    $injector,
    LocalStorage,
    Modules,
    userService,
    betshopService,
    WorkingTime,
    companyService,
    ConfigService,
    currency,
    LoginData,
    LocalData,
    NabENV,
    LocalAppConfig,
    SevenClientCoreSvc,
    GameService,
    SevenGamesSvc,
    ShopEnvironment,
    GravitySettings,
    GravityMenus,
    Touch,
    GravityApplication,
    GravityForms,
    Widgets,
    GravityLayouts,
    GravityGatewayIntegrator,
    errorParser,
    StateAssembler,
    customerCardsService,
    SevenMaintenanceService,
    ScanSvc
  ) {
    var bootstrapData = {};
    var self = this;
    let mainAppLoaded = false;
    let baseUrl = NabENV.api.gravity.shop.replace(
      NabENV.cloudflare.endpoints.bootstrap.search,
      NabENV.cloudflare.endpoints.bootstrap.replace
    );

    function setLanguageProvider(lang) {
      ShopEnvironment.setLanguage(lang);
      angular.module('SevenClientLib').provider('lang', function Lang() {
        this.lang = lang;
        this.$get = function () {
          return lang;
        };
      });
    }

    function injectAngularLocale(lang) {
      var loader = Modules.getLoader();
      var mapper = NabENV.angular.i18nMapper;
      lang = has(mapper, lang) ? mapper[lang] : lang;
      return loader.load('node_modules/angular-i18n/angular-locale_' + lang + '.js');
    }

    /**
     * Fetch bootstrap data from gravity api. It will return only
     * translations for now
     *
     * @param {Object} params
     * @param {String} params.lang
     * @param {String} params.companyUuid
     * @param {String} params.applicationName - gravity application name
     * @param {Number} params.betshopId
     * @param {Boolean | String} params.dc
     */
    function getShopGravityBootstrap(params) {
      var config = {
        headers: {
          'X-Nsft-SCD-App-Name': params.applicationName,
          'X-Nsft-SCD-Locale': params.lang,
          'X-Nsft-SCD-Company-Id': params.companyUuid,
          'X-Nsft-SCD-Betshop-Id': params.betshopId,
          'X-Nsft-SCD-Betshop-Uuid': params.betshopUuid,
          'X-Nsft-SCD-Version': '5'
        }
      };

      return $http.get(baseUrl + '/bootstrap', config);
    }

    function setNumberDelimiter(numberDelimiter) {
      var $locale = $injector.get('$locale');
      var groupSeparator = numberDelimiter[1];
      var decimalSeparator = numberDelimiter[numberDelimiter.length - 3];
      if (groupSeparator === '#') { groupSeparator = ''; }
      $locale.NUMBER_FORMATS.GROUP_SEP = groupSeparator;
      $locale.NUMBER_FORMATS.DECIMAL_SEP = decimalSeparator;
    }

    function parseNonCFRoute(url) {
      var dcParsed = url.match(/-([a-zA-Z0-9]*)\./);
      var dc = dcParsed ? dcParsed[1] : false;
      return dc;
    }

    function parseDC(str) {
      const lastDotIndex = str.lastIndexOf('.');
      // Find the index of the next-to-last occurrence of '.'
      const secondLastDotIndex = str.lastIndexOf('.', lastDotIndex - 1);
      const thirdLastDotIndex = str.lastIndexOf('.', secondLastDotIndex - 1);

      if (thirdLastDotIndex === -1) {
        return parseNonCFRoute(str);
      }

      return str.substring(thirdLastDotIndex + 1, secondLastDotIndex).replace('-', '');
    }

    function loadBootstrap(lang) {
      var companyUuid = LocalData.betshop.companyUuid;
      var betshopId = LocalData.betshop.betshopId;
      var betshopUuid = LocalData.betshop.uuid;
      var deviceId = LocalData.device.deviceId;
      var count = 0;
      var dc = parseDC(LocalAppConfig.sevenUrl);

      ShopEnvironment.setDc(dc);

      // after we fetched gravity app name by companyUuid
      // we can now proceed to fetching all data binded that application
      return getShopGravityBootstrap({
        lang: lang,
        companyUuid: companyUuid,
        applicationName: GravityApplication.getAppName(),
        betshopId: betshopId,
        betshopUuid: betshopUuid,
        dc: dc
      }).then(function (res) {
        var touchModulePromise = null;
        var shouldReload = false;
        var numDelimiter;
        var widgetsToRegister;
        bootstrapData = res.data;
        GravityMenus.setData(bootstrapData.menus);
        GravityForms.setData(bootstrapData.forms);
        GravitySettings.setData(bootstrapData.appSettings);

        numDelimiter = GravitySettings.getModuleDataKeyValue('config', 'numberDelimiter');
        if (numDelimiter) { setNumberDelimiter(numDelimiter); }

        // override gravity app setting with local storage defintion if
        // exist
        each(GravitySettings.getData(), function (value, key) {
          var localOverwrite = JSON.parse(localStorage.getItem(key));
          if (localOverwrite) {
            GravitySettings.updateSetting(key, localOverwrite);
          }
        });

        widgetsToRegister = Widgets.filterWidgetsFromSource(GravitySettings.getData());
        each(widgetsToRegister, function (widget) {
          Widgets.registerWidget(widget);
        });
        GravityLayouts.setData(bootstrapData.ticketLayouts);
        Modules.registerModules(GravitySettings.getByKey('modules') || {});

        // detect channel and do reload if we did not pick up channel assets
        shouldReload = ShopEnvironment.setChannel(betshopId, deviceId);
        if (shouldReload) {
          $window.location.reload();
        }

        ScanSvc.setConfig(GravitySettings.getByKey('module.BarcodeScanner'));
        StateAssembler.createRoutes(GravitySettings.getByKey('module.Router.states'));
        // $window.location.replace is async,
        // if there is redirect give it 6 sec otherwise keep loading default channel
        return $timeout(function () {
          Modules.setData(GravitySettings.getByKey('modules') || {});

          // refresh so shopLanguageLoader can re-register translations
          $translate.refresh();

          // if we are in touch mode load dedicated touch module
          // and set envirnoment state touch as true
          if (ShopEnvironment.isInTouchMode()) {
            Touch.setInTouch();
            touchModulePromise = Modules.loadModule('7Shop.Touch');
          }

          // lazyLoad modules, position: afterGravityBootstrap
          return $q.all([
            Modules.loadModulesByPosition('afterGravityBootstrap'),
            touchModulePromise
          ]).finally(function () {
            $rootScope.$emit('7S:Bootstrap.Ready');
            return self.loadTheme(
              $window.ShopEnvironment.getUrlFlag('theme'),
              GravitySettings.getModuleDataKeyValue('config', 'theme')
            );
          });
        }, shouldReload ? 6000 : 0, false);
      }).catch(function (err) {
        $log.error('[7Shop.Bootstrap] Bootstrap error detected.', {
          ...errorParser.parseUpstream(err),
          code: 'S_BOOTSTRAP_HTTP_ERR',
          details: {
            baseUrl,
            count
          }
        });
        if (baseUrl !== NabENV.api.gravity.shop && count < 2) { // If previous try is with non-cloudflare route, don't do retry so we don't get infinite loop
          baseUrl = NabENV.api.gravity.shop;
          count += 1;
          loadBootstrap(lang);
        }
      });
    }

    this.boot = function () {
      return GravityApplication.fetchGravityAppName(LocalData.betshop.companyUuid).then(function (resData) {
        if (resData) {
          GravityApplication.setAppName(resData.data.app.name);
        }
        return true;
      }).finally(function () {
        var storedLang = LocalStorage.getItem('settings.language');
        var storedLangOverride = LocalStorage.getItem('settings.languageOverride');
        var needInstallationFlag;
        let arModulePromise;

        if (ShopEnvironment.isDmActiveRuntime()) {
          arModulePromise = Modules.loadModule('dm');
        } else {
          arModulePromise = Modules.loadModule('nar');
        }

        return $q.all([storedLang, storedLangOverride, arModulePromise]).then(function (data) {
          var lang;

          if (data[1]) {
            lang = data[1];
          } else {
            lang = !!data[0] ? data[0] : 'en'; // eslint-disable-line
          }

          // Set language as provider
          setLanguageProvider(lang);

          needInstallationFlag = $window
            .ShopEnvironment
            .getFlagState($window.ShopEnvironment.FLAGS.NEED_INSTALLATION);

          return injectAngularLocale(lang)
            .catch(function (err) {
              $log.warn('[7Shop.Bootstrap] No ngLocale found', {
                code: 'S_BOOTSTRAP_LOCALE_FETCH_ERR',
                details: {
                  lang,
                  storedLang,
                  storedLangOverride
                },
                ...errorParser.parseUpstream(err)
              });
            }).finally(function () {
              if (needInstallationFlag && needInstallationFlag.active === true) {
                return $q.resolve();
              }

              return loadBootstrap(lang);
            });
        });
      });
    };

    this.loadMainApp = function (loginData) {
      var currencySetting;
      var currencyDisplayValue;

      GameService.mergeGravityConfigWithProducts(loginData.clientAppModules);

      LoginData.setData(loginData);
      userService.setData(loginData);
      betshopService.setData({
        ...loginData.betshop,
        betshopGroup: loginData.betshopGroup,
        device: LocalData.device
      });

      companyService.setData(loginData.company);
      ConfigService.setData();

      companyService.setSettings(bootstrapData.appSettings.config || {});

      customerCardsService.init();
      SevenMaintenanceService.init();
      currencySetting = companyService.getSetting('currency');
      currencyDisplayValue = GravitySettings.getModuleDataKeyValue('currency', 'displayValue');
      currency.value = currencyDisplayValue !== undefined ? currencyDisplayValue
        : companyService.data && companyService.data.currency;
      if (currencySetting) {
        currency.position = currencySetting.position;
      }

      // load login
      return Modules.loadModule('home').then(function () {
        if (mainAppLoaded) {
          // prevent running SevenClientCoreSvc.run two times
          return true;
        }

        mainAppLoaded = true;
        $log.debug('[7Shop.Login] Loaded home module on login');
        return SevenClientCoreSvc.run(loginData);
      }).then(function () {
        $log.debug('[7Shop.Login] Client core init done.');
        return $state.go('app.home');
      }).then(function () {
        $log.debug('[7Shop.Login] Bootstraping iframe products.');
        return $q.all(GameService.boostrapIframeProducts(
          SevenGamesSvc.getGames(),
          GravityGatewayIntegrator.registerIframeModule.bind(GravityGatewayIntegrator)
        ));
      })
        .then(function () {
          $log.debug('[7Shop.Login] Setting working time on loadMainApp.');
          WorkingTime.init(loginData.workingTime);
          return true;
        });
    };

    this.loadLogin = function () {
      Modules.loadModule('auth').then(() => $state.go('app.login'));
    };

    this.handleSessionLogin = function (token) {
      return userService.checkSession(token, NabENV.api.gravity.shop, GravityApplication.getAppName(), LocalData.betshop.uuid)
        .then(function (session) {
          if (session.status === 200) {
            return self.loadMainApp(session.data).catch(function (err) {
              $log.warn('[7Shop.Login] loadMainApp failed on handleSessionLogin', {
                code: 'S_BOOTSTRAP_SESSESION_ERR',
                upstream_code: errorParser.parseCode(err),
                upstream_message: errorParser.parseMessage(err)
              });
              return err;
            });
          }
          return self.loadLogin();
        })
        .catch(function () {
          return self.loadLogin();
        });
    };

    this.getBootstrapData = function () {
      return bootstrapData;
    };

    this.fetchTranslations = function () {
      var browserLang = $translate.resolveClientLocale().substring(0, 2).toLowerCase();
      return $http.get(NabENV.api.gravity.shop + '/translations', {
        headers: {
          'X-Nsft-SCD-Locale': browserLang || 'en'
        }
      });
    };

    /**
     * @param {String} themeName
     * @param {String} companyThemeName
     */
    this.loadTheme = function (themeName, companyThemeName) {
      // we need this in order for cache bust to find files for rewrite
      var THEMES = {
        touch_default: () => import(
          /* webpackChunkName: "theme_touch_default" */
          'theme_touch_default'),
        desktop_default: () => import(/* webpackChunkName: "theme_desktop_default" */
          'theme_desktop_default'),
        'desktop_black-yellow': () => import(
          /* webpackChunkName: "desktop_black-yellow" */
          'theme_desktop_black-yellow'),
        'desktop_dark-green': () => import(
          /* webpackChunkName: "desktop_dark-green" */
          'theme_desktop_dark-green'),
        desktop_magenta: () => import(
          /* webpackChunkName: "theme_desktop_magenta" */
          'theme_desktop_magenta')
      };
      var loadTheme = themeName;
      var deviceType = Touch.isTouch() ? 'touch' : 'desktop';

      if (!themeName && companyThemeName) {
        loadTheme = companyThemeName;
      } else if (!themeName || !has(THEMES, deviceType + '_' + loadTheme)) {
        loadTheme = 'default';
      }

      return THEMES[deviceType + '_' + loadTheme]();
    };
  }
})();
